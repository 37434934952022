.filter-container button {
margin-right: 0.7rem;
min-width: 5rem;
padding: 0.2rem 0.8rem;
border: none;
background: white;
color:#283660 ;
border-radius: 1rem;
border: 2px solid #283660;
font-weight: bold;
cursor: pointer;
}
button.activee{
  background:#283660 ;
  color: white;
}
.group58 {
    width: 1150px;
    height: 99px;
    display: flex;
    padding: 0;
    position: relative;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
  }
  .group26 {
    top: 0px;
    left: 0px;
    width: 1150px;
    height: 99px;
    display: flex;
    padding: 0;
    position: absolute;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    border-radius: 0px 0px 0px 0px;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
  }
  .group14 {
    top: 0px;
    left: 0px;
    width: 1150px;
    height: 99px;
    display: flex;
    padding: 0;
    position: absolute;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    border-radius: 0px 0px 0px 0px;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #28366033;
  }
  .rectangle {
    top: 16px;
    left: 46px;
    width: 69px;
    height: 69px;
    position: absolute;
    border-color: transparent;
    border-radius: 8.449999809265137px;
  }
  .rectangle1 {
    top: 15px;
    left: 46.83258056640625px;
    width: 123px;
    height: 84px;
    position: absolute;
    border-color: transparent;
  }
  .rectangle2 {
    top: 28px;
    left: 46.83258056640625px;
    width: 95px;
    height: 55px;
    position: absolute;
    border-color: transparent;
    border-radius: 8.449999809265137px;
  }
  .rectangle3 {
    top: 15px;
    left: 499.5475158691406px;
    width: 43px;
    height: 84px;
    position: absolute;
    border-color: transparent;
  }
  .rectangle4 {
    top: 15px;
    left: 541.1764526367188px;
    width: 236px;
    height: 84px;
    position: absolute;
    border-color: transparent;
  }
  .rectangle5 {
    top: 15px;
    left: 777.0739135742188px;
    width: 154px;
    height: 84px;
    position: absolute;
    border-color: transparent;
  }
  .rectangle6 {
    top: 15px;
    left: 931.4479370117188px;
    width: 109px;
    height: 84px;
    position: absolute;
    border-color: transparent;
  }
  .text {
    top: 30px;
    left: 169.98486328125px;
    color: rgba(24, 28, 50, 1);
    width: 180px;
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: 20.962499618530273px;
    font-stretch: normal;
    text-decoration: none;
  }
  .text02 {
    top: 54px;
    left: 169.98486328125px;
    color: rgba(161, 165, 183, 1);
    width: 219px;
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: 20.962499618530273px;
    font-stretch: normal;
    text-decoration: none;
  }
  .text04 {
    top: 54px;
    left: 349.98486328125px;
    color: rgba(161, 165, 183, 1);
    width: 219px;
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: 20.962499618530273px;
    font-stretch: normal;
    text-decoration: none;
  }
  .text06 {
    top: 54px;
    left: 743.98486328125px;
    color: rgba(161, 165, 183, 1);
    width: 219px;
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: 20.962499618530273px;
    font-stretch: normal;
    text-decoration: none;
  }
  .vector {
    top: 32px;
    left: 62px;
    width: 37px;
    height: 37px;
    position: absolute;
  }
  .text08 {
    top: 30px;
    left: 331px;
    color: rgba(24, 28, 50, 1);
    width: 95px;
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: right;
    font-family: Poppins;
    font-weight: 400;
    line-height: 20.962499618530273px;
    font-stretch: normal;
    text-decoration: none;
  }
  .text10 {
    top: 30px;
    left: 540px;
    color: rgba(24, 28, 50, 1);
    width: 95px;
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: right;
    font-family: Poppins;
    font-weight: 400;
    line-height: 20.962499618530273px;
    font-stretch: normal;
    text-decoration: none;
  }
  .text12 {
    top: 30px;
    left: 706px;
    color: rgba(24, 28, 50, 1);
    width: 95px;
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: right;
    font-family: Poppins;
    font-weight: 400;
    line-height: 20.962499618530273px;
    font-stretch: normal;
    text-decoration: none;
  }
  .rectangle7 {
    top: 40px;
    left: 1043px;
    width: 72px;
    height: 24px;
    position: absolute;
    border-color: transparent;
    border-radius: 8.449999809265137px;
  }
  .text14 {
    top: 42px;
    left: 1059px;
    color: rgba(255, 255, 255, 1);
    width: 34px;
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: auto;
    font-style: SemiBold;
    text-align: right;
    font-family: Poppins;
    font-weight: 600;
    line-height: 20.962499618530273px;
    font-stretch: normal;
    text-decoration: none;
  }
  .rectangle8 {
    top: 40px;
    left: 934px;
    width: 91px;
    height: 24px;
    position: absolute;
    border-color: transparent;
    border-radius: 8.449999809265137px;
  }
  .text16 {
    top: 42px;
    left: 940px;
    color: rgba(255, 255, 255, 1);
    width: 64px;
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: auto;
    font-style: SemiBold;
    text-align: right;
    font-family: Poppins;
    font-weight: 600;
    line-height: 20.962499618530273px;
    font-stretch: normal;
    text-decoration: none;
  }
