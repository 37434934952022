
    @tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #ffffff;
}
/* Override default focus colors for tailwindcss-forms https://github.com/tailwindlabs/tailwindcss-forms */
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='search']:focus,
[type='checkbox']:focus,
[type='radio']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: var(--primary-color);
  border-color: var(--primary-color);
}

.react-datepicker-wrapper .react-datepicker__input-container input{
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 0px;
}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none;
  color: black;
}
input:focus, textarea:focus{
  outline:none !important;
  outline-offset: 0 !important;
  --tw-ring-offset-width:none !important;
}

li:focus-visible{
  outline: none;
}

li:hover{
  cursor: pointer;
}

.tab-panel--selected { animation-name: tabPanel; animation-duration: 300ms; animation-timing-function: linear; }
@keyframes tabPanel { from { opacity: 0; } to { opacity: 1; } }
/* Input style */
.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-container label {
  position: absolute;
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container input {
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.columngroupfiftynine{
 @apply  flex items-center not-italic xl:px-[11px] 2xl:px-[13px] 3xl:px-[15px] lg:px-[9px] rounded-radius50 text-pink_400 w-[auto]; 
}
.options{
 @apply bg-white_A700 not-italic lg:pl-[11px] xl:pl-[14px] 2xl:pl-[16px] 3xl:pl-[19px] lg:pr-[24px] xl:pr-[31px] 2xl:pr-[35px] 3xl:pr-[42px] xl:py-[12px] 2xl:py-[14px] 3xl:py-[16px] lg:py-[9px] text-gray_900 w-[134px]; 
}
.soldCounter{
 @apply 2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] xl:mt-[8px] not-italic text-bluegray_300 w-[auto]; 
}
.BioPerfectus{
 @apply lg:mt-[32px] xl:mt-[40px] 2xl:mt-[46px] 3xl:mt-[55px] not-italic text-bluegray_800 w-[auto]; 
}
.boughtCounter{
 @apply 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[7px] xl:mt-[9px] not-italic text-bluegray_300 w-[auto]; 
}
.Arsho{
 @apply lg:mt-[29px] xl:mt-[37px] 2xl:mt-[42px] 3xl:mt-[50px] not-italic text-bluegray_800 w-[auto]; 
}
.ICL_One{
 @apply lg:mt-[30px] xl:mt-[38px] 2xl:mt-[43px] 3xl:mt-[51px] not-italic text-bluegray_800 w-[auto]; 
}
.time_One{
 @apply xl:mt-[12px] 2xl:mt-[14px] 3xl:mt-[16px] lg:mt-[9px] not-italic text-bluegray_800 w-[auto]; 
}
.Feb1022_One{
 @apply ml-[2px] mt-[2px] not-italic text-bluegray_300 w-[auto]; 
}
.Feb1022_Three{
 @apply ml-[2px] mt-[4px] not-italic text-bluegray_300 w-[auto]; 
}
.Laboratories{
 @apply 2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] xl:mt-[8px] not-italic text-gray_900 w-[auto]; 
}
.Feb{
 @apply mb-[1px] not-italic text-bluegray_600 w-[auto]; 
}
.columnloremipsumlor{
 @apply lg:leading-[32px] xl:leading-[40px] 2xl:leading-[45px] 3xl:leading-[54px] lg:mb-[100px] xl:mb-[125px] 2xl:mb-[141px] 3xl:mb-[169px] lg:mt-[192px] xl:mt-[241px] 2xl:mt-[271px] 3xl:mt-[325px] not-italic text-center text-white_A700 w-[99%]; 
}
.MyprojectEleven1{
 @apply lg:h-[150px] xl:h-[187px] 2xl:h-[211px] 3xl:h-[253px] w-[100%]; 
}
.user_One{
 @apply lg:h-[27px] xl:h-[33px] 2xl:h-[38px] 3xl:h-[45px] lg:w-[26px] xl:w-[32px] 2xl:w-[37px] 3xl:w-[44px]; 
}
.AbebeKebede{
 @apply mt-[1px] not-italic text-gray_900 w-[auto]; 
}
.Laboratory{
 @apply lg:ml-[56px] xl:ml-[70px] 2xl:ml-[79px] 3xl:ml-[94px] mt-[1px] not-italic text-gray_900 w-[auto]; 
}
.Salesperson{
 @apply xl:ml-[110px] 2xl:ml-[124px] 3xl:ml-[148px] lg:ml-[88px] mt-[1px] not-italic text-gray_900 w-[auto]; 
}
.test_09111111111{
 @apply 2xl:ml-[108px] 3xl:ml-[129px] lg:ml-[76px] xl:ml-[96px] not-italic text-gray_900 w-[auto]; 
}
.abe{
 @apply mt-[1px] not-italic text-bluegray_300 w-[auto]; 
}
.InternationalL{
 @apply lg:ml-[108px] xl:ml-[135px] 2xl:ml-[152px] 3xl:ml-[182px] not-italic text-bluegray_300 w-[auto]; 
}
.email{
 @apply lg:ml-[194px] xl:ml-[242px] 2xl:ml-[273px] 3xl:ml-[327px] mt-[1px] not-italic text-bluegray_300 w-[auto]; 
}
.GroupSeventyTwo{
 @apply font-normal not-italic p-[0] xl:text-[12px] 2xl:text-[14px] 3xl:text-[16px] lg:text-[9px] text-gray_900 w-[100%]; 
}
.GroupTwentyThree{
 @apply font-normal not-italic p-[0] lg:text-[11px] xl:text-[14px] 2xl:text-[16px] 3xl:text-[20px] text-gray_900 w-[100%]; 
}
.FirstName{
 @apply mb-[2px] not-italic text-gray_900 w-[auto]; 
}
.rowinternationalc{
 @apply lg:mb-[5px] xl:mb-[6px] 2xl:mb-[7px] 3xl:mb-[8px] 2xl:ml-[10px] 3xl:ml-[12px] lg:ml-[7px] xl:ml-[9px] not-italic text-gray_900 w-[auto]; 
}
.iconBoxes{
 @apply absolute lg:h-[22px] xl:h-[27px] 2xl:h-[30px] 3xl:h-[36px] inset-[0] justify-center m-[auto] w-[69%]; 
}
.columnibufrofen{
 @apply lg:ml-[120px] xl:ml-[151px] 2xl:ml-[169px] 3xl:ml-[203px] mt-[2px] not-italic text-bluegray_300 w-[auto]; 
}
.test_209000Qty{
 @apply 3xl:ml-[1008px] lg:ml-[597px] xl:ml-[747px] 2xl:ml-[840px] mt-[2px] not-italic text-gray_900 w-[auto]; 
}
