:root { 
--gray_51:#fbfbfb; 
/* --hover:#d4d4d4;  */
--bluegray_300_87:#919eab87; 
--green_400_b2:#283660b2; 
--gray_900_99:#181c3299; 
--gray_50:#f5f8fa; 
--red_50:#ffeff3; 
--green_400:#283660; 
--green_A400:#20d489; 
--indigo_900_cc:#ffffffcc; 
--black_900:#000000; 
--pink_400:#f1416c; 
--black_900_0a:#0000000a; 
--gray_400:#C4CDD5; 
--indigo_50:#e4e6ef; 
--gray_900:#181c32; 
--teal_50_e5:#d8e3f0e5; 
--gray_100:#f5f5f5; 
--bluegray_800:#3f4254;
--gray:rgba(35, 52, 103, 0.5);;
--bluegray_600:#5e6278; 
--green_400_cc:#283660cc; 
--green_400_33:#28366033; 
--bluegray_400:#7e8299; 
/* --gray_300:#C4CDD5; */
--bluegray_300:#a1a5b7; 
--gray_900_b2:#181433b2; 
--indigo_100_e5:#bed1e6e5; 
--red_700_cc:#be3b3bcc; 
--indigo_900:#ffffff; 
--white_A700:#ffffff; 
}